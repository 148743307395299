<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <button class="btn btn-info m-2" type="button" @click="updated">Update Selected Item</button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12" md="6" lg="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Date :"
            label-for="daterange"
          >
            <b-input-group
            >
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <month-range-picker id="daterange" :start="startDate" :end="endDate" @picker="doDateFilter"></month-range-picker>
              <b-input-group-append>
                <b-button type="button" variant="secondary" @click="resetDateFilter">Reset</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <vuetable ref="vuetable"
      @vuetable:load-error="handleLoadError"
      :api-url="apiUrl"
      :http-options="HttpOptions"
      :fields="fields"
      pagination-path=""
      :header-rows="headerRows"
      :muti-sort="true"
      :sort-order="sortOrder"
      :append-params="moreParams"
      @vuetable:pagination-data="onPaginationData">
        <!-- <template slot="tableHeader">
          <tr>
            <th rowspan="3">Transaction Date</th>
            <th rowspan="3">Transaction Time</th>
            <th rowspan="3">Customer ID</th>
            <th rowspan="3">Trade ID</th>
            <th colspan="2">Customer Digital Gold Purchase</th>
            <th colspan="2">Customer Digital Gold Sales</th>
            <th colspan="2">Customer Transfer</th>
            <th colspan="2">Customer Minting Gold</th>
          </tr>
          <tr>
            <th rowspan="2">Gram</th>
            <th rowspan="2">Price</th>
            <th rowspan="2">Gram</th>
            <th rowspan="2">Price</th>
            <th>In</th>
            <th>Out</th>
            <th>Deposit</th>
            <th>Withdraw</th>
          </tr>
          <tr>
            <th>Gram</th>
            <th>Gram</th>
            <th>Gram</th>
            <th>Gram</th>
          </tr>
        </template> -->
        <template slot="actions-slot" slot-scope="prop">
          <div class="custom-actions">
            <button v-if="prop.rowData.status_btn === true" type="button" :class="prop.rowData.status === true ? 'btn btn-danger' : 'btn btn-success'" @click="onAction('edit-item', prop.rowData)" >{{ prop.rowData.status === true ? 'Hide' : 'Show' }}</button>&nbsp;
          </div>
        </template>
      </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <vuetable-pagination-info ref="paginationInfo"
      ></vuetable-pagination-info>

      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>
</template>

<script>
  import accounting from 'accounting'
  import Vue from 'vue'
  import Vuetable from 'vuetable-2/src/components/Vuetable'
  import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
  import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
  import VuetableRowHeader from 'vuetable-2/src/components/VuetableRowHeader.vue'
  import RowHeader from './RowHeader'
  import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';


  let startDate = '';
  let endDate = '';
  Vue.use(Vuetable);

  export default {
    components: {
      Vuetable,
      VuetablePagination,
      VuetablePaginationInfo
    },
    prop: {
      rowData: {
        type: Object,
        required: true
      },
      rowIndex: {
        type: Number
      }
    },
    data() {
      return {
        startDate: '',
        endDate: '',
        user: [],
        isLoading: false,
        largeModal: false,
        filterText: '',
        message: '',
        dateDetail: {
          start_from: '',
          end_from: ''
        },
        headerRows: [RowHeader, VuetableRowHeader],
        errors: {
          code: '',
          message: '',
          status: ''
        },
        apiUrl: process.env.VUE_APP_SECRET + process.env.VUE_APP_CONFIG + `filters`,
        HttpOptions: {
          headers: {
            'Accept' : 'application/json',
            'Authorization' : 'Bearer ' + localStorage.getItem('access_token')
          }
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'asc'
          },{
            field: 'time',
            sortField: 'time',
            direction: 'asc'
          }
        ],
        moreParams: {},
        fields: [
          {
            name: VuetableFieldCheckbox,   // <----
            titleClass: 'center aligned',
            dataClass: 'center aligned',
            width: '5%'
          },
          {
            name: 'created_at',
            sortField: 'created_at',
            title: 'Transaction Date'
          },
          {
            name: 'time',
            sortField: 'time',
            title: 'Transaction Time'
          },
          {
            name: 'customer_id',
            title: 'Customer ID'
          },
          {
            name: 'transaction_id',
            title: 'Transaction ID'
          },
          {
            name: 'purchase.gram',
            title: 'Gram'
          },
          {
            name: 'purchase.price',
            title: 'Price'
          },
          {
            name: 'sales.gram',
            title: 'Gram'
          },
          {
            name: 'sales.price',
            title: 'Price'
          },
          {
            name: 'transfer.out',
            title: 'Transfer Out (Gram)'
          },
          {
            name: 'transfer.in',
            title: 'Transfer In (Gram)'
          },
          {
            name: 'gold.deposit',
            title: 'Deposit (Gram)'
          },
          {
            name: 'gold.withdraw',
            title: 'Withdraw (Gram)'
          },
          {
            name: 'actions-slot',
            title: 'Action'
          }
        ]
      }
    },
    methods: {
      updated() {
        if (this.$refs.vuetable.selectedTo.length === 0) {
          return this.$swal.fire(
              'Failed',
              'Please Select Items for run this action!',
              'error'
            )
        }
        this.isLoading = true
        this.$http.post(`multiple-filters`, {
            items: this.$refs.vuetable.selectedTo,
        }).then((res) => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              res.data.meta.message,
              'success'
            ).then(() => {
              location.reload()
            })
        }).catch((error) => {
            if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.message = error.response.data.meta.message;
                this.errors.status = error.response.data.meta.code;
                if (this.errors.status  == 400) {
                    this.$swal.fire(
                        'Failed',
                        this.errors.message,
                        'error'
                    ).then(() => {
                        location.reload()
                    })
                }else if (this.errors.status  == 404) {
                    this.$swal.fire(
                        'Failed',
                        this.errors.message,
                        'error'
                    ).then(() => {
                        location.reload()
                    })
                }
            }
        })
      },
      statusField(value) {
        return value == 0 ? 'Sedang Diproses' : (value == 1 ? 'Berhasil' : (value == 2 ? 'Gagal' : '-'))
      },
      goldField(value) {
        return value + ' Gram'
      },
      doFilter () {
        this.$events.$emit('filter-set', this.filterText)
      },
      resetFilter () {
        this.filterText = ''  // clear the text in text input
        this.$events.$emit('filter-reset')
      },
      exportTable() {
        this.isLoading = true
        if (this.startDate != '' && this.endDate != '') {
          startDate = this.startDate;
          endDate = this.endDate;
        }else{
          endDate = this.$setDate.format('YYYY-MM-DD');
          startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
        }
        this.$http.get(`withdraw/sell/` + startDate + `/` + endDate)
        .then((result) => {
          this.isLoading =false
          const  exportPath = result.data.meta.data;
          window.location.href = exportPath;
        }).catch((error) => {
          if (error.response) {
            this.isLoading = false
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        })
      },
      handleLoadError(error) {
        this.errors.code = error.response.data.meta.code;
        this.errors.message = error.response.data.meta.message;
        this.errors.status = error.response.data.meta.code;
        if (this.errors.code == 401) {
          if (localStorage.getItem('access_token') != null) {
            localStorage.removeItem('access_token');
            this.$swal.fire(
              'Your session expired!',
              'Your session has expired. Please login again to access this page!',
              'error'
            ).then(() =>{
              this.$router.push("/login")
            })
          }
        }else if(this.errors.code == 403) {
          this.$router.push("/403")
        }else if(this.errors.code == 500) {
          this.$router.push("/500")
        }
      },
      resetDateFilter () {
        this.startDate = ''
        this.endDate = ''
        this.$events.$emit('date-filter-reset')
      },
      formatNumber (value) {
        return 'Rp.'+accounting.formatNumber(value, 2)
      },
      number(value){
        return value
      },
      onChangePage (page) {
        this.$refs.vuetable.changePage(page)
      },
      onPaginationData (paginationData) {
        this.$refs.pagination.setPaginationData(paginationData)
        this.$refs.paginationInfo.setPaginationData(paginationData)
      },
      onAction (action, data) {
        this.isLoading = true
        let status = data.status === true ? false : true
        this.$http.post(`filters/` + data.id, {
            filter: status,
            _method: 'put'
        }).then((res) => {
            this.isLoading = false
            this.$swal.fire(
              'Success!',
              res.data.meta.message,
              'success'
            ).then(() => {
              location.reload()
            })
        }).catch((error) => {
            if (error.response) {
                this.isLoading = false
                this.errors.code = error.response.status;
                this.errors.message = error.response.data.meta.message;
                this.errors.status = error.response.data.meta.code;
                if (this.errors.status  == 400) {
                    this.$swal.fire(
                        'Failed',
                        this.errors.message,
                        'error'
                    ).then(() => {
                        location.reload()
                    })
                }else if (this.errors.status  == 404) {
                    this.$swal.fire(
                        'Failed',
                        this.errors.message,
                        'error'
                    ).then(() => {
                        location.reload()
                    })
                }
            }
        })
      },
      onFilterSet (filterText) {
          this.moreParams = {
              'filter': filterText
          }
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      doDateFilter(value){
        this.startDate = value.startDate;
        this.endDate = value.endDate;
        this.$events.fire('date-set', [this.startDate, this.endDate])
      },
      onDateSet()  {
        this.start_from = this.startDate;
        this.end_to = this.endDate;
        this.moreParams = {
          'start_from': this.start_from,
          'end_to': this.end_to
        }
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onFilterReset () {
          this.moreParams = {}
          Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      onDateFilterReset () {
        this.moreParams = {}
        Vue.nextTick( () => this.$refs.vuetable.refresh())
      },
      check(user) {
        setInterval(this.$http.post(`check`, {
          bank_id: user.bank.bank_id,
          account_number: user.bank.account_number
        }).then((response) => {
            this.user.bank.owner_name = response.data.data.name
            this.user.bank.account_number = response.data.data.account_number
            this.message = '<h5 style="color:green">' + response.data.meta.message + '</h5>'
        }).catch((error) =>{
          if (error.response) {
            this.message = '<h5 style="color:red">' + error.response.data.meta.message + '</h5>'
          }
        }), 5000)
      }
    },
    mounted() {
      this.$events.$on('filter-set', eventData => this.onFilterSet(eventData))
      this.$events.$on('date-set', eventData => this.onDateSet(eventData))
      this.$events.$on('filter-reset', () => this.onFilterReset())
      this.$events.$on('date-filter-reset', () => this.onDateFilterReset())
    },
  }
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

  footer.modal-footer {
      display: none;
  }

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
